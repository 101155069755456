import Navbar from "../NavMenu"
import { Link } from "react-router-dom";

const EmailConfirmed = () => {
    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    <div className="text-center max-w-3xl mx-auto">
                        <div className="rounded-2xl border border-green-200 bg-white shadow-sm p-8">
                            <div className="mx-auto w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                                <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <h1 className="text-2xl font-bold text-gray-900 mb-4">
                                Email Confirmed!
                            </h1>
                            <p className="text-gray-600 mb-6">
                                Your email has been successfully confirmed. You can now log in to your account.
                            </p>
                            <Link
                                to="/login"
                                className="inline-block py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                            >
                                Go to Login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailConfirmed