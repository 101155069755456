import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../NavMenu';
import { Mail, RefreshCw, CheckCircle, AlertCircle, HomeIcon, CreditCard } from 'lucide-react';

const RegistrationSuccess = () => {
    const [resendStatus, setResendStatus] = useState('idle');
    const email = sessionStorage.getItem('registeredEmail');
    const navigate = useNavigate();

    const handleResendVerification = async () => {
        if (resendStatus === 'loading') return;

        setResendStatus('loading');
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/resend-verification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setResendStatus('success');
            } else {
                setResendStatus('error');
            }
        } catch (error) {
            setResendStatus('error');
        }
    };

    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-3xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8">
                        {/* Email Icon */}
                        <div className="text-center">
                            <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-6">
                                <Mail className="h-8 w-8 text-blue-600" />
                            </div>

                            <h1 className="text-3xl font-bold text-gray-900 mb-4">
                                Welcome to Dwellio!
                            </h1>

                            <p className="text-lg text-gray-600 mb-2">
                                We've sent a verification link to
                            </p>
                            <p className="font-medium text-blue-600 text-lg">
                                {email}
                            </p>
                        </div>

                        {/* Verification Instructions */}
                        <div className="mt-8 bg-gray-50 p-6 rounded-lg space-y-4">
                            <h2 className="font-semibold text-gray-900">What's next?</h2>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-start">
                                    <span className="font-medium mr-2">1.</span>
                                    Check your email and click the verification link
                                </li>
                                <li className="flex items-start">
                                    <span className="font-medium mr-2">2.</span>
                                    If you don't see the email, check your spam folder
                                </li>
                                <li className="flex items-start">
                                    <span className="font-medium mr-2">3.</span>
                                    Once verified, you can start using all features
                                </li>
                            </ul>
                        </div>

                        {/* Resend Verification */}
                        <div className="mt-6">
                            <button
                                onClick={handleResendVerification}
                                disabled={resendStatus === 'loading' || resendStatus === 'success'}
                                className={`w-full flex items-center justify-center px-4 py-3 border rounded-lg shadow-sm text-sm font-medium 
                                    ${resendStatus === 'loading' || resendStatus === 'success'
                                    ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                                    : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                            >
                                {resendStatus === 'loading' ? (
                                    <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
                                ) : resendStatus === 'success' ? (
                                    <CheckCircle className="w-4 h-4 mr-2 text-green-500" />
                                ) : (
                                    <Mail className="w-4 h-4 mr-2" />
                                )}
                                {resendStatus === 'loading'
                                    ? 'Sending...'
                                    : resendStatus === 'success'
                                        ? 'Email sent!'
                                        : 'Resend verification email'}
                            </button>
                        </div>

                        {/* Status Messages */}
                        {resendStatus === 'success' && (
                            <div className="mt-4 p-4 rounded-lg bg-green-50 flex items-center text-green-700">
                                <CheckCircle className="w-4 h-4 mr-2" />
                                <p>Verification email has been resent. Please check your inbox.</p>
                            </div>
                        )}

                        {resendStatus === 'error' && (
                            <div className="mt-4 p-4 rounded-lg bg-red-50 flex items-center text-red-700">
                                <AlertCircle className="w-4 h-4 mr-2" />
                                <p>Failed to resend verification email. Please try again later.</p>
                            </div>
                        )}

                        {/* Get Started Section */}
                        <div className="mt-8 pt-8 border-t border-gray-200">
                            <div className="text-center mb-6">
                                <h3 className="text-xl font-semibold text-gray-900">Ready to find your perfect rental?</h3>
                                <p className="mt-2 text-gray-600">
                                    Once your email is verified, choose your subscription plan and start receiving personalized property alerts.
                                </p>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <button
                                    onClick={() => navigate('/subscriptions')}
                                    className="flex items-center justify-center px-4 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 transition-colors"
                                >
                                    <CreditCard className="w-5 h-5 mr-2" />
                                    View Subscription Plans
                                </button>

                                <button
                                    onClick={() => navigate('/login')}
                                    className="flex items-center justify-center px-4 py-3 border border-gray-300 rounded-lg shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                                >
                                    <HomeIcon className="w-5 h-5 mr-2" />
                                    Go to Login
                                </button>
                            </div>

                            <div className="mt-6 bg-blue-50 border border-blue-100 rounded-md p-4">
                                <p className="text-sm text-blue-800">
                                    <span className="font-semibold">Pro Tip:</span> Our Pro plan includes instant notifications, expanded search radius, and priority access to new listings. Perfect for finding your ideal property before anyone else!
                                </p>
                            </div>
                        </div>

                        {/* Support Section */}
                        <div className="mt-8 text-center">
                            <p className="text-sm text-gray-500">
                                Need help? <a href="mailto:support@dwellio.com" className="text-blue-600 hover:text-blue-700 font-medium">Contact Support</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegistrationSuccess;