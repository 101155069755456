import React, { useState } from 'react';
import {
    Settings,
    Bell,
    Sliders,
    PlusCircle,
    PauseCircle,
    Trash2,
    Mail,
    CheckCircle2
} from 'lucide-react';
import Navbar from './NavMenu';
import { Helmet } from 'react-helmet-async';
import Footer from "./Footer";
import { useNavigate } from 'react-router-dom';

const ImageCarousel = ({ images, title }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="relative w-full rounded-xl overflow-hidden bg-gray-100">
            <div className="aspect-[16/9] relative overflow-hidden rounded-lg shadow-lg">
                <img
                    src={images[currentIndex].url}
                    alt={images[currentIndex].caption}
                    className="w-full h-full object-cover transition-all duration-500"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-black/50 text-white p-4">
                    <p className="text-sm">{images[currentIndex].caption}</p>
                </div>
            </div>

            <button
                onClick={goToPrevious}
                className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all"
            >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </button>

            <button
                onClick={goToNext}
                className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all"
            >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
            </button>

            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
                {images.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`w-2 h-2 rounded-full transition-all ${
                            index === currentIndex ? 'bg-white scale-125' : 'bg-white/50'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

const HowItWorks = () => {
    const navigate = useNavigate();
    const handleNavigateToSubscriptions = () => {
        navigate('/subscriptions');
        window.scrollTo(0, 0);
    };
    // Example screenshot data - replace with your actual screenshots
    const preferencesScreenshots = [
        {
            url: "/addnewpreference.png",
            caption: "Set your location and search radius"
        },
        {
            url: "/preferenceadded.png",
            caption: "Preference saved and we're looking for results"
        }
    ];

    const notificationsScreenshots = [
        {
            url: "/EmailReceived.png",
            caption: "Email notification example"
        }
    ];

    const dashboardScreenshots = [
        {
            url: "/MatchedProperties.png",
            caption: "Main dashboard overview"
        },
        {
            url: "/PausePreference.png",
            caption: "Managing your preferences"
        },
        {
            url: "/DeletePreference.png",
            caption: "Managing your preferences"
        }
    ];

    const steps = [
        {
            title: "Set Your Property Preferences",
            icon: <Settings className="w-6 h-6 text-blue-500" />,
            description: "Define your ideal property by selecting location, price range, property type, and number of bedrooms.",
            details: [
                "Choose your desired location and search radius",
                "Set minimum and maximum price ranges",
                "Select property types you're interested in",
                "Specify bedroom requirements"
            ],
            screenshots: preferencesScreenshots
        },
        {
            title: "Receive Instant Notifications",
            icon: <Bell className="w-6 h-6 text-blue-500" />,
            description: "Get notified as soon as properties matching your criteria are listed.",
            details: [
                "Email notifications for new matches",
                "Daily summaries of new properties",
                "Important account updates",
                "Subscription status notifications"
            ],
            screenshots: notificationsScreenshots
        },
        {
            title: "Manage Your Dashboard",
            icon: <Sliders className="w-6 h-6 text-blue-500" />,
            description: "Access all your preferences and matched properties in one place.",
            details: [
                "View all matched properties",
                "Add new search preferences",
                "Pause or resume notifications",
                "Delete unwanted preferences"
            ],
            screenshots: dashboardScreenshots
        }
    ];

    const features = [
        {
            title: "Add Preferences",
            icon: <PlusCircle className="w-6 h-6 text-green-500" />,
            description: "Create multiple search preferences"
        },
        {
            title: "Pause/Resume",
            icon: <PauseCircle className="w-6 h-6 text-yellow-500" />,
            description: "Temporarily pause notifications"
        },
        {
            title: "Remove Preferences",
            icon: <Trash2 className="w-6 h-6 text-red-500" />,
            description: "Delete unwanted preferences"
        },
        {
            title: "Email Updates",
            icon: <Mail className="w-6 h-6 text-purple-500" />,
            description: "Receive customized notifications"
        }
    ];

    return (
        <>
            <Helmet>
                <title>How Dwellio Works - Your Guide to Smart Property Notifications</title>
                <meta
                    name="description"
                    content="Learn how Dwellio helps you find your perfect rental property. Set personalized preferences, receive instant notifications, and never miss out on your dream home. Our step-by-step guide to smarter property searching."
                />
                <meta
                    name="keywords"
                    content="property alerts, rental notifications, property search guide, real estate notifications, property finder tutorial, Dwellio guide"
                />
                <meta
                    property="og:title"
                    content="How Dwellio Works - Your Guide to Smart Property Notifications"
                />
                <meta
                    property="og:description"
                    content="Learn how Dwellio helps you find your perfect rental property. Set personalized preferences, receive instant notifications, and never miss out on your dream home."
                />
                <meta
                    property="og:type"
                    content="website"
                />
                <link
                    rel="canonical"
                    href="https://dwellio.co.uk/how-it-works"
                />
            </Helmet>
            <Navbar/>
            <div className="container mx-auto px-4 py-8">
                {/* Hero Section */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-4">How It Works</h1>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                        Find your perfect property with our intelligent property matching system.
                        Here's how to get started and make the most of our service.
                    </p>
                </div>

                {/* Main Steps with Carousels */}
                <div className="space-y-12 mb-16">
                    {steps.map((step, index) => (
                        <div key={index} className="space-y-6">
                            <div className="bg-white rounded-lg shadow-md p-6 border-l-4 border-l-blue-500">
                                <div className="flex items-center gap-4 mb-4">
                                    {step.icon}
                                    <h2 className="text-2xl font-bold">{step.title}</h2>
                                </div>
                                <p className="text-gray-600 mb-4">{step.description}</p>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {step.details.map((detail, idx) => (
                                        <div key={idx} className="flex items-center gap-2">
                                            <CheckCircle2 className="w-5 h-5 text-green-500"/>
                                            <span>{detail}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Screenshot Carousel */}
                            <div className="w-full max-w-4xl mx-auto">
                                <ImageCarousel
                                    images={step.screenshots}
                                    title={step.title}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                {/* Features Grid */}
                <h2 className="text-2xl font-bold mb-8 text-center">Key Features</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
                    {features.map((feature, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-md p-6">
                            <div className="flex items-center gap-3 mb-3">
                                {feature.icon}
                                <h3 className="text-lg font-semibold">{feature.title}</h3>
                            </div>
                            <p className="text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>

                {/* Get Started Section */}
                <div className="bg-blue-50 rounded-lg shadow-md p-8 text-center">
                    <h2 className="text-2xl font-bold mb-4">Ready to Find Your Perfect Property?</h2>
                    <p className="text-gray-600 mb-6">
                        Start your property search today and never miss out on your dream home.
                    </p>
                    <button
                        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                        onClick={handleNavigateToSubscriptions}
                    >
                        Get Started
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HowItWorks;