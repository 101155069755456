import React, { useMemo, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Home, Search, MapPin, Check } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import Navbar from './NavMenu';

const PropertySearchLanding = () => {
    const { city } = useParams();

    // Create formatted city name and slug
    const formattedCity = useMemo(() => {
        return city.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }, [city]);

    const citySlug = useMemo(() => {
        return city.toLowerCase();
    }, [city]);

    // Structured data for SEO
    const getStructuredData = (city) => ({
        "@context": "https://schema.org",
        "@type": "RealEstateListing",
        "name": `Rental Properties in ${city}`,
        "description": `Find and get instant notifications for rental properties in ${city}. Get alerted within 15 minutes of new listings with Dwellio.`
    });

    // Track page view
    useEffect(() => {
        if (window.gtag) {
            window.gtag('event', 'page_view', {
                page_title: `Rental Properties in ${formattedCity}`,
                page_path: `/rent/${citySlug}`
            });
        }
    }, [formattedCity, citySlug]);

    return (
        <>
            <Navbar/>
            <Helmet>
                <title>Rental Properties in {formattedCity} | Dwellio</title>
                <meta
                    name="description"
                    content={`Find rental properties in ${formattedCity} with Dwellio. Get instant notifications within 15 minutes of new listings, set unlimited search areas, and be first to view properties. Start your search in ${formattedCity} today.`}
                />
                <meta
                    name="keywords"
                    content={`${formattedCity} rentals, properties to rent ${formattedCity}, ${formattedCity} property search, rental alerts ${formattedCity}`}
                />
                <link rel="canonical" href={`https://dwellio.co.uk/rent/${citySlug}`} />
                <script type="application/ld+json">
                    {JSON.stringify(getStructuredData(formattedCity))}
                </script>
            </Helmet>

            <div className="max-w-6xl mx-auto px-4 py-8">
                {/* Hero Section */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-4">
                        Find Properties to Rent in {formattedCity}
                    </h1>
                    <p className="text-xl text-gray-600 mb-8">
                        Discover your perfect rental property in {formattedCity} with real-time notifications and personalized
                        searches
                    </p>
                </div>

                {/* Features Grid */}
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <Home className="w-12 h-12 text-blue-600 mb-4"/>
                        <h3 className="text-xl font-semibold mb-2">Instant Notifications</h3>
                        <p className="text-gray-600">
                            Get notified as soon as new properties matching your criteria become available in {formattedCity}
                        </p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <Search className="w-12 h-12 text-blue-600 mb-4"/>
                        <h3 className="text-xl font-semibold mb-2">Smart Search</h3>
                        <p className="text-gray-600">
                            Set your preferences for property type, budget, and location within {formattedCity}
                        </p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <MapPin className="w-12 h-12 text-blue-600 mb-4"/>
                        <h3 className="text-xl font-semibold mb-2">Location Focus</h3>
                        <p className="text-gray-600">
                            Find properties in specific areas of {formattedCity} that match your requirements
                        </p>
                    </div>
                </div>

                {/* City-specific Stats Section */}
                <div className="bg-gray-50 p-8 rounded-lg mt-12">
                    <h2 className="text-2xl font-bold mb-6">Rental Search in {formattedCity}</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="text-center">
                            <div className="text-3xl font-bold text-blue-600 mb-2">15 min</div>
                            <p className="text-gray-600">Maximum alert delay for new properties</p>
                        </div>
                        <div className="text-center">
                            <div className="text-3xl font-bold text-blue-600 mb-2">24/7</div>
                            <p className="text-gray-600">Continuous property monitoring</p>
                        </div>
                        <div className="text-center">
                            <div className="text-3xl font-bold text-blue-600 mb-2">3x</div>
                            <p className="text-gray-600">More likely to secure viewings</p>
                        </div>
                    </div>
                </div>

                {/* CTA Section */}
                <div className="bg-blue-50 p-8 rounded-lg text-center mt-12">
                    <h2 className="text-2xl font-bold mb-4">
                        Start Your Property Search in {formattedCity} Today
                    </h2>
                    <p className="text-gray-600 mb-6">
                        Create your account now and get instant access to the latest property listings in {formattedCity}
                    </p>
                    <Link
                        to="/register"
                        className="inline-block bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                    >
                        Get Started
                    </Link>
                </div>

                {/* Plans Section */}
                <div className="mt-12">
                    <h2 className="text-2xl font-bold mb-6">Choose Your Perfect Plan in {formattedCity}</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="border rounded-lg p-6">
                            <h3 className="font-semibold mb-2">Free Plan</h3>
                            <ul className="space-y-2 text-gray-600">
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    Daily property updates
                                </li>
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    Single search area
                                </li>
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    Basic email alerts
                                </li>
                            </ul>
                        </div>
                        <div className="border rounded-lg p-6 border-blue-200 bg-blue-50">
                            <h3 className="font-semibold mb-2">Pro Plan</h3>
                            <ul className="space-y-2 text-gray-600">
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    15-minute notifications
                                </li>
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    Unlimited search areas
                                </li>
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    Priority listing access
                                </li>
                            </ul>
                        </div>
                        <div className="border rounded-lg p-6">
                            <h3 className="font-semibold mb-2">Basic Plan</h3>
                            <ul className="space-y-2 text-gray-600">
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    3 daily updates
                                </li>
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    3 search areas
                                </li>
                                <li className="flex items-center">
                                    <Check className="h-4 w-4 text-green-500 mr-2"/>
                                    Standard alerts
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Property Info Section */}
                <div className="mt-12">
                    <h2 className="text-2xl font-bold mb-6">About Renting in {formattedCity}</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div>
                            <h3 className="text-xl font-semibold mb-3">Popular Areas</h3>
                            <p className="text-gray-600">
                                Discover rental properties across {formattedCity}'s most sought-after neighborhoods.
                                Whether you're looking for a quiet suburban area or a bustling city center location,
                                we'll help you find the perfect property.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-3">Rental Market</h3>
                            <p className="text-gray-600">
                                Stay up-to-date with {formattedCity}'s dynamic rental market. Our real-time notifications
                                ensure you never miss out on new properties that match your criteria as soon as
                                they become available.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertySearchLanding;