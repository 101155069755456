import React, { useState } from 'react';
import {
  AlertCircle,
  ArrowUpRight,
  Clock
} from 'lucide-react';
import CustomPagination from './CustomPagination';
import EmptyState from './EmptyState';
import ListingCard from './MobileView/ListingCard';

const MatchedListingsTable = ({ listings = [], userTier, preferences = [], onAddPreference }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 20;

  const hasActivePreferences = preferences.some(pref => pref.isActive);
  const effectiveTier = userTier;
  const totalMatchedCount = preferences?.reduce((acc, pref) => acc + (pref.listingsMatched || 0), 0) || 0;

  if (!listings?.length) {
    return (
        <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 relative">
          <div className="mb-6">
            <h2 className="text-2xl font-semibold text-gray-900">Matched Properties</h2>
            <p className="text-gray-600 mt-1">Properties matching your search criteria</p>
          </div>

          <div className="hidden md:block">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bedrooms</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Added</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notification</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td colSpan="7">
                  {hasActivePreferences ? (
                      <div className="text-center py-12">
                        <div className="flex flex-col items-center max-w-sm mx-auto">
                          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-50 mb-4">
                            <Clock className="h-6 w-6 text-blue-500" />
                          </div>
                          <h3 className="text-lg font-semibold text-gray-900 mb-2">
                            Looking for matches
                          </h3>
                          <p className="text-gray-600">
                            We're actively searching for properties that match your preferences. Check back soon!
                          </p>
                        </div>
                      </div>
                  ) : (
                      <EmptyState type="listings" onAction={onAddPreference} />
                  )}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="md:hidden">
            {hasActivePreferences ? (
                <div className="text-center py-8">
                  <div className="flex flex-col items-center max-w-sm mx-auto">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-50 mb-4">
                      <Clock className="h-6 w-6 text-blue-500" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      Looking for matches
                    </h3>
                    <p className="text-gray-600">
                      We're actively searching for properties that match your preferences. Check back soon!
                    </p>
                  </div>
                </div>
            ) : (
                <EmptyState type="listings" onAction={onAddPreference} />
            )}
          </div>
        </div>
    );
  }

  // Get listings based on user tier
  const getVisibleListings = () => {
    let filteredListings;
    if (effectiveTier === 'Free') {
      filteredListings = listings.slice(0, 5);
    } else if (effectiveTier === 'Basic') {
      filteredListings = listings.slice(0, 20);
    } else {
      const startIndex = (currentPage - 1) * listingsPerPage;
      const endIndex = startIndex + listingsPerPage;
      filteredListings = listings.slice(startIndex, endIndex);
    }
    return filteredListings;
  };

  const visibleListings = getVisibleListings();
  const hasMoreListings = totalMatchedCount > visibleListings.length;
  const totalPages = effectiveTier === 'Pro' ? Math.ceil(listings.length / listingsPerPage) : 1;

  return (
      <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 relative">
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-900">Matched Properties</h2>
          <p className="text-gray-600 mt-1">Properties matching your search criteria</p>
        </div>

        {effectiveTier === 'Basic' && hasMoreListings && (
            <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
              <div className="flex items-start space-x-3">
                <AlertCircle className="h-5 w-5 text-blue-500 flex-shrink-0 mt-0.5"/>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {totalMatchedCount - visibleListings.length} More Properties Available
                  </h3>
                  <p className="text-gray-600 mt-1 mb-4">
                    Upgrade to Pro to unlock all matched properties and get instant notifications.
                  </p>
                  <button
                      onClick={() => window.location.href = '/subscriptions'}
                      className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md text-sm font-medium transition-colors"
                  >
                    Upgrade to Pro
                  </button>
                </div>
              </div>
            </div>
        )}

        {/* Desktop View */}
        <div className="hidden md:block overflow-x-auto">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bedrooms</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Added</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notification</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {visibleListings.map((listing) => (
                <tr key={listing.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{listing.city}</td>
                  <td className="px-6 py-4 whitespace-nowrap">£{listing.price.toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{listing.propertyType}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{listing.actualBedrooms}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(listing.dateAdded).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {listing.dateNotificationSent
                        ? new Date(listing.dateNotificationSent).toLocaleDateString()
                        : 'Not Sent'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <a
                        href={listing.listingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800"
                    >
                      View Listing
                    </a>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>

        {/* Mobile View */}
        <div className="md:hidden">
          <div className="space-y-4">
            {visibleListings.map(listing => (
                <div key={listing.id} className="border border-gray-200 rounded-lg overflow-hidden">
                  <ListingCard listing={listing} />
                </div>
            ))}
          </div>
        </div>

        {effectiveTier === 'Free' && hasMoreListings && (
            <div className="mt-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
              <div className="text-center">
                <AlertCircle className="h-12 w-12 text-blue-500 mx-auto mb-4"/>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {totalMatchedCount - visibleListings.length} More Properties Found!
                </h3>
                <p className="text-gray-600 mb-4">
                  Unlock all properties by upgrading to Pro
                </p>
                <ul className="text-left space-y-2 mb-4 max-w-xs mx-auto">
                  <li className="flex items-center gap-2 text-gray-700">
                    <ArrowUpRight className="h-4 w-4 text-green-500 flex-shrink-0"/>
                    <span>See all matched properties</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-700">
                    <ArrowUpRight className="h-4 w-4 text-green-500 flex-shrink-0"/>
                    <span>Get instant notifications</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-700">
                    <ArrowUpRight className="h-4 w-4 text-green-500 flex-shrink-0"/>
                    <span>Access detailed information</span>
                  </li>
                </ul>
                <button
                    onClick={() => window.location.href = '/subscriptions'}
                    className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg font-medium transition-colors"
                >
                  Upgrade Now
                </button>
              </div>
            </div>
        )}

        {effectiveTier === 'Pro' && (
            <div className="mt-6">
              <CustomPagination
                  currentPage={currentPage}
                  totalPageCount={totalPages}
                  onPageChange={setCurrentPage}
                  userTier={effectiveTier}
              />
            </div>
        )}
      </div>
  );
};

export default MatchedListingsTable;