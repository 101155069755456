// CookieConsent.js
import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const cookieTypes = {
    essential: {
        name: "Essential Cookies",
        description: "Required for basic site functionality (e.g., Stripe payments)",
        required: true
    },
    analytics: {
        name: "Analytics Cookies",
        description: "Help us understand how visitors interact with our website",
        required: false
    }
    // Add other cookie types as needed
};

const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [preferences, setPreferences] = useState({
        essential: true, // Essential cookies are always true
        analytics: false
    });

    useEffect(() => {
        // Check if user has already consented
        const hasConsented = localStorage.getItem('cookieConsent');
        if (!hasConsented) {
            setShowBanner(true);
        } else {
            // Load saved preferences
            try {
                const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
                if (savedPreferences) {
                    setPreferences(savedPreferences);
                }
            } catch (error) {
                console.error('Error loading cookie preferences:', error);
            }
        }
    }, []);

    const handleAccept = () => {
        const allAccepted = Object.keys(cookieTypes).reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});

        localStorage.setItem('cookieConsent', 'true');
        localStorage.setItem('cookiePreferences', JSON.stringify(allAccepted));
        setPreferences(allAccepted);
        setShowBanner(false);
        // Here you could initialize Google Analytics
    };

    const handleDecline = () => {
        const declinePreferences = {
            essential: true, // Essential cookies are always accepted
            analytics: false
        };

        localStorage.setItem('cookieConsent', 'true');
        localStorage.setItem('cookiePreferences', JSON.stringify(declinePreferences));
        setPreferences(declinePreferences);
        setShowBanner(false);
        // Here you could disable non-essential tracking
    };

    if (!showBanner) return null;

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
            <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                    <div className="flex-1">
                        <p className="text-sm text-gray-600">
                            We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. We share certain information about your use of our site with our social media and analytics partners. By clicking "Accept All", you consent to our use of cookies.
                        </p>
                    </div>
                    <div className="flex items-center gap-4">
                        <button
                            onClick={handleDecline}
                            className="text-gray-600 hover:text-gray-900 text-sm font-medium"
                        >
                            Decline Non-Essential
                        </button>
                        <button
                            onClick={handleAccept}
                            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium rounded-lg transition-colors"
                        >
                            Accept All
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;