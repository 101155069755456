import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Context/AuthProvider';

export const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ redirectTo: location.pathname }} />;
    }

    return children;
};

export const PublicOnlyRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const pendingSubscription = localStorage.getItem('pendingSubscription');
    const isRegistrationSuccess = location.pathname === '/registration-success';

    if (isAuthenticated) {
        // If there's a pending subscription or it's the registration success page, don't redirect
        if (pendingSubscription || isRegistrationSuccess) {
            return children;
        }
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }
    return children;
};

export const ProtectedAdminRoute = ({ children }) => {
    const { isAdmin } = useAuth();

    if (!isAdmin) {
        return <Navigate to="/admin" replace />;
    }

    return children;
};