import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "./NavMenu";
import SubscriptionPrompt from "./SubscriptionPrompt";
import PreferencesTable from "./PreferencesTable";
import SubscriptionStatusCard from "./SubscriptionStatusCard";
import MatchedListingsTable from "./MatchedListingsTable";
import AddOrEditPreferenceModal from "./AddOrEditPreferenceModal";
import {
    Spinner,
    Pagination,
    OverlayTrigger,
    Tooltip,
    Button,
} from "react-bootstrap"; // removed alert
import CustomPagination from "./CustomPagination";
import SubscriptionRequiredModal from "./SubscriptionRequiredModal";
import "../custom.css";
import {
    AlertCircle,
    Bell,
    Plus,
    Settings,
    Edit3,
    Pause,
    Play,
    Trash2,
    CheckCircle2,
    Clock,
    AlertTriangle,
    ArrowRight
} from "lucide-react";
import EmailVerificationModal from "./Auth/EmailVerificationModal";
import Footer from "./Footer";

// Since you don't have the shadcn/ui components set up, let's create a simple Alert component
const Alert = ({ children, className }) => (
    <div
        className={`p-4 rounded-lg bg-white shadow-lg border border-gray-200 ${className}`}
    >
        {children}
    </div>
);

const AlertTitle = ({ children }) => (
    <h4 className="text-lg font-semibold mb-1">{children}</h4>
);

const AlertDescription = ({ children }) => (
    <p className="text-gray-600">{children}</p>
);

const Dashboard = () => {
    const [preferences, setPreferences] = useState([]);
    const [matchedListings, setMatchedListings] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState({
        PriceRange: [],
        PropertyType: [],
        DistanceRadius: [],
        Bedrooms: [],
    });
    const [newPreference, setNewPreference] = useState({
        city: "",
        priceRangeMin: "",
        priceRangeMax: "",
        propertyType: "",
        distanceRadius: "",
        minBedrooms: "",
        maxBedrooms: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editingPreferenceId, setEditingPreferenceId] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [listingsPerPage] = useState(20);
    const [preferenceLoaded, setPreferenceLoaded] = useState(false);
    const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [dashboardAccess, setDashboardAccess] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const indexOfLastListing = currentPage * listingsPerPage;
    const indexOfFirstListing = indexOfLastListing - listingsPerPage;
    const currentListings = matchedListings.slice(
        indexOfFirstListing,
        indexOfLastListing,
    );
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessage("");
    };

    const handleShowModal = () => {
        // Add console.log for debugging
        console.log('Showing modal', {
            subscriptionStatus,
            currentPreferences: preferences.length,
            maxPreferences: subscriptionStatus?.maxPreferences
        });

        if (subscriptionStatus && preferences.length >= subscriptionStatus.maxPreferences) {
            alert("You have reached the maximum number of preferences allowed by your subscription.");
            return;
        }

        // Reset the form when showing modal
        setNewPreference({
            city: '',
            priceRangeMin: '',
            priceRangeMax: '',
            propertyType: '',
            distanceRadius: '',
            minBedrooms: '',
            maxBedrooms: ''
        });

        setIsEditing(false);
        setEditingPreferenceId(null);
        setPreferenceLoaded(false);
        setShowModal(true);
    };

    const fetchListings = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }

        try {
            const preferencesResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setPreferences(preferencesResponse.data);

            const matchedListingsResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/matched-listings`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setMatchedListings(
                matchedListingsResponse.data.sort(
                    (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded),
                ),
            );
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        const checkDashboardAccess = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
                return;
            }

            setIsLoading(true);
            try {
                // First check dashboard access (which includes email verification)
                const accessResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/access`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // If email is verified, get subscription details
                const subscriptionResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Check if user has an active subscription
                if (!subscriptionResponse.data || subscriptionResponse.data.status === "No Subscription") {
                    setShowSubscriptionPrompt(true);
                    setShowEmailVerificationModal(false);
                    setDashboardAccess(false);
                } else {
                    setSubscriptionStatus(subscriptionResponse.data);
                    setShowSubscriptionPrompt(false);
                    setShowEmailVerificationModal(false);
                    setDashboardAccess(true);
                    await fetchListings();
                }

            } catch (error) {
                console.error("Error checking dashboard access:", error);
                setShowSubscriptionPrompt(true);
                setShowEmailVerificationModal(false);
                setDashboardAccess(false);
            } finally {
                setIsLoading(false);
            }
        };
        checkDashboardAccess();
    }, [navigate]);

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/preferences/dropdown-options`,
                );
                setDropdownOptions(response.data);
            } catch (error) {
                console.error("Error fetching dropdown options:", error);
            }
        };

        fetchDropdownOptions();
    }, []);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
                return;
            }

            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );

                // Check if the user has any subscription
                if (!response.data || response.data.status === "No Subscription") {
                    setShowSubscriptionPrompt(true);
                    setDashboardAccess(false);
                } else {
                    setSubscriptionStatus(response.data);
                    setShowSubscriptionPrompt(false);
                    setDashboardAccess(true);
                }
            } catch (error) {
                setShowSubscriptionPrompt(true);
                setDashboardAccess(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [navigate]);

    useEffect(() => {
        const fetchListings = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
                return;
            }

            try {
                const preferencesResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                setPreferences(preferencesResponse.data);

                const matchedListingsResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/listings/matched-listings`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                setMatchedListings(
                    matchedListingsResponse.data.sort(
                        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded),
                    ),
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchListings();
    }, [navigate]);

    const handleChange = (e) => {
        setNewPreference({
            ...newPreference,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        if (
            !newPreference.city ||
            !newPreference.priceRangeMin ||
            !newPreference.priceRangeMax ||
            !newPreference.propertyType ||
            !newPreference.distanceRadius ||
            !newPreference.minBedrooms ||
            !newPreference.maxBedrooms
        ) {
            setErrorMessage("Please fill in all fields before submitting.");
            return false;
        }
        setErrorMessage("");
        return true;
    };

    const handleEditPreference = (id) => {
        setIsEditing(true);
        setEditingPreferenceId(id);
        const preferenceToEdit = preferences.find((pref) => pref.id === id);

        if (preferenceToEdit) {
            const formatPrice = (price) => {
                return `£${price.toLocaleString("en-UK")}`;
            };

            const formatRadius = (radius) => {
                return `${radius} miles`;
            };

            const updatedPreference = {
                city: preferenceToEdit.city || "",
                priceRangeMin: formatPrice(preferenceToEdit.priceRangeMin),
                priceRangeMax: formatPrice(preferenceToEdit.priceRangeMax),
                propertyType: preferenceToEdit.propertyType || "",
                distanceRadius: formatRadius(preferenceToEdit.distanceRadius),
                minBedrooms:
                    preferenceToEdit.minBedrooms === 0
                        ? "Studio"
                        : preferenceToEdit.minBedrooms.toString(),
                maxBedrooms: preferenceToEdit.maxBedrooms.toString(),
            };

            setNewPreference(updatedPreference);
            setPreferenceLoaded(true);
            setShowModal(true);
        }
    };

    const handleDeletePreference = async (id) => {
        const token = localStorage.getItem("token");
        setIsDeleting(true);
        try {
            await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/delete/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            // Fetch updated preferences after deletion
            try {
                const preferencesResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                setPreferences(preferencesResponse.data);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // Handle case when no preferences exist
                    setPreferences([]); // Set preferences to an empty array
                } else {
                    console.error("Error fetching updated preferences:", error);
                }
            }
        } catch (error) {
            console.error("Error deleting preference:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleUpdatePreference = async () => {
        if (!validateForm()) return;

        const token = localStorage.getItem("token");
        const parsedPriceRangeMin = newPreference.priceRangeMin.replace(
            /[^\d.]/g,
            "",
        );
        const parsedPriceRangeMax = newPreference.priceRangeMax.replace(
            /[^\d.]/g,
            "",
        );
        const parsedDistanceRadius = newPreference.distanceRadius.replace(
            /[^\d]/g,
            "",
        );
        const parsedMinBedrooms =
            newPreference.minBedrooms === "Studio"
                ? 0
                : parseInt(newPreference.minBedrooms);
        const parsedMaxBedrooms =
            newPreference.maxBedrooms === "Studio"
                ? 0
                : parseInt(newPreference.maxBedrooms);
        console.log(newPreference);
        try {
            const preferenceToSubmit = {
                ...newPreference,
                priceRangeMin: parsedPriceRangeMin
                    ? parseFloat(parsedPriceRangeMin)
                    : null,
                priceRangeMax: parsedPriceRangeMax
                    ? parseFloat(parsedPriceRangeMax)
                    : null,
                distanceRadius: parsedDistanceRadius
                    ? parseInt(parsedDistanceRadius)
                    : null,
                minBedrooms: parsedMinBedrooms,
                maxBedrooms: parsedMaxBedrooms,
            };
            console.log(preferenceToSubmit);
            await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/update/${editingPreferenceId}`,
                preferenceToSubmit,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setIsEditing(false);
            setEditingPreferenceId(null);
            setNewPreference({
                city: "",
                priceRangeMin: "",
                priceRangeMax: "",
                propertyType: "",
                distanceRadius: "",
                minBedrooms: "",
                maxBedrooms: "",
            });
            handleCloseModal();

            const preferencesResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setPreferences(preferencesResponse.data);
        } catch (error) {
            console.error("Error updating preference:", error);
        }
    };

    const handleAddPreference = async () => {
        if (
            !subscriptionStatus ||
            preferences.length >= subscriptionStatus.maxPreferences
        ) {
            alert(
                "You have reached the maximum number of preferences allowed by your subscription.",
            );
            return;
        }
        if (!validateForm()) return;

        const token = localStorage.getItem("token");

        const parsedPriceRangeMin = newPreference.priceRangeMin.replace(
            /[^\d.]/g,
            "",
        );
        const parsedPriceRangeMax = newPreference.priceRangeMax.replace(
            /[^\d.]/g,
            "",
        );
        const parsedDistanceRadius = newPreference.distanceRadius.replace(
            /[^\d]/g,
            "",
        );
        const parsedMinBedrooms =
            newPreference.minBedrooms === "Studio"
                ? 0
                : parseInt(newPreference.minBedrooms);
        const parsedMaxBedrooms =
            newPreference.maxBedrooms === "Studio"
                ? 0
                : parseInt(newPreference.maxBedrooms);
        try {
            const preferenceToSubmit = {
                ...newPreference,
                priceRangeMin: parsedPriceRangeMin
                    ? parseFloat(parsedPriceRangeMin)
                    : null,
                priceRangeMax: parsedPriceRangeMax
                    ? parseFloat(parsedPriceRangeMax)
                    : null,
                distanceRadius: parsedDistanceRadius
                    ? parseInt(parsedDistanceRadius)
                    : null,
                minBedrooms: parsedMinBedrooms,
                maxBedrooms: parsedMaxBedrooms,
            };

            setIsAdding(true);
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/add`,
                preferenceToSubmit,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            setNewPreference({
                city: "",
                priceRangeMin: "",
                priceRangeMax: "",
                propertyType: "",
                distanceRadius: "",
                minBedrooms: "",
                maxBedrooms: "",
            });

            handleCloseModal();

            const preferencesResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setPreferences(preferencesResponse.data);
        } catch (error) {
            console.error("Error adding preference:", error);
        } finally {
            setIsAdding(false);
        }
    };

    // const renderAddPreferenceButton = () => {
    //     const isDisabled =
    //         !subscriptionStatus ||
    //         preferences.length >= subscriptionStatus.maxPreferences;
    //     return (
    //         <OverlayTrigger
    //             placement="top"
    //             overlay={
    //                 <Tooltip>
    //                     {isDisabled
    //                         ? "Upgrade your subscription to add more preferences."
    //                         : "Add a new preference"}
    //                 </Tooltip>
    //             }
    //         >
    //             <span className="d-inline-block">
    //                 <Button
    //                     variant="primary"
    //                     onClick={() => {
    //                         setIsEditing(false);
    //                         handleShowModal();
    //                     }}
    //                     disabled={isDisabled}
    //                     style={isDisabled ? { pointerEvents: "none" } : {}}
    //                 >
    //                     Add New Preference
    //                 </Button>
    //             </span>
    //         </OverlayTrigger>
    //     );
    // };


    const handleStartFreeTrial = () => {
        // Logic to handle starting the free trial
        alert(
            "Free trial started! You can now access Tier 3 features for a week.",
        );
        // Redirect or trigger any other actions as needed
    };

    const handleSubscribe = (tier) => {
        // Logic to handle the subscription process based on the selected tier
        alert(`Subscribed to ${tier} plan successfully!`);
        // You can add any additional logic for processing the subscription or redirecting the user
    };

    const handlePausePreference = async (id, isActive) => {
        const token = localStorage.getItem("token");
        if (!token) {
            alert("User is not authenticated. Please log in.");
            return;
        }

        try {
            const updateDto = {
                id: id,
                isActive: isActive,
            };

            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/preferences/update-status`,
                updateDto,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                },
            );

            // Update the preferences list with the new state
            const updatedPreferences = preferences.map((pref) =>
                pref.id === id ? {...pref, isActive: isActive} : pref,
            );
            setPreferences(updatedPreferences);
        } catch (error) {
            console.error("Error updating preference status:", error);
            alert("Failed to update preference status. Please try again.");
        }
    };

    const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);

    return (
        <div className="min-h-screen bg-gray-50">
            <Navbar/>

            {isLoading ? (
                <div className="flex items-center justify-center min-h-screen">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                </div>
            ) : !dashboardAccess ? (
                <>
                    <EmailVerificationModal
                        show={showEmailVerificationModal}
                        onClose={() => setShowEmailVerificationModal(false)}
                    />
                    <SubscriptionRequiredModal
                        show={showSubscriptionPrompt}
                        onClose={() => setShowSubscriptionPrompt(false)}
                    />
                </>
            ) : (
                <div className="min-h-screen bg-gray-50">

                    {isLoading ? (
                        <div className="flex items-center justify-center min-h-screen">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                        </div>
                    ) : !dashboardAccess ? (
                        <SubscriptionRequiredModal
                            onClose={() => setShowSubscriptionPrompt(false)}
                        />
                    ) : (
                        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                            <div className="flex justify-between items-center mb-6">
                                <h1 className="text-2xl font-bold text-gray-900">
                                    Dashboard
                                </h1>
                                <button
                                    onClick={handleShowModal}
                                    disabled={
                                        preferences.length >=
                                        subscriptionStatus?.maxPreferences
                                    }
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    <Plus className="h-4 w-4 mr-2"/>
                                    Add Preference
                                </button>
                            </div>

                            {subscriptionStatus && (
                                <SubscriptionStatusCard
                                    status={subscriptionStatus.status}
                                    expiresIn={subscriptionStatus.expiresIn}
                                    maxPreferences={subscriptionStatus.maxPreferences}
                                    notificationDelay={
                                        subscriptionStatus.notificationDelayMinutes
                                    }
                                    preferencesCount={preferences.length}
                                />
                            )}

                            {preferences.length >= subscriptionStatus?.maxPreferences && (
                                <div
                                    className="mb-6 bg-gradient-to-r from-blue-50 to-indigo-50 border border-blue-100 rounded-lg p-6">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <div className="flex-shrink-0">
                                                <AlertCircle className="h-6 w-6 text-blue-500"/>
                                            </div>
                                            <div>
                                                <h3 className="text-sm font-medium text-blue-800">
                                                    Preference Limit Reached
                                                </h3>
                                                <p className="text-sm text-blue-600 mt-1">
                                                    You've reached the maximum number of preferences for your current
                                                    plan.
                                                </p>
                                            </div>
                                        </div>
                                        <a
                                            href="/subscriptions"
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                                        >
                                            Upgrade Now
                                            <ArrowRight className="ml-2 h-4 w-4"/>
                                        </a>
                                    </div>
                                </div>
                            )}

                            <PreferencesTable
                                preferences={preferences}
                                onEdit={handleEditPreference}
                                onDelete={handleDeletePreference}
                                onPause={handlePausePreference}
                                onAddPreference={handleShowModal}
                            />

                            <AddOrEditPreferenceModal
                                showModal={showModal}
                                handleCloseModal={handleCloseModal}
                                handleChange={handleChange}
                                handleAddPreference={handleAddPreference}
                                handleUpdatePreference={handleUpdatePreference}
                                newPreference={newPreference}
                                dropdownOptions={dropdownOptions}
                                isEditing={isEditing}
                                preferenceLoaded={preferenceLoaded}
                                isAdding={isAdding}
                                userTier={subscriptionStatus?.status}
                            />

                            {subscriptionStatus && !isLoading && (
                                <MatchedListingsTable
                                    listings={matchedListings}
                                    showModal={showModal}
                                    handleCloseModal={handleCloseModal}
                                    handleChange={handleChange}
                                    handleAddPreference={handleAddPreference}
                                    handleUpdatePreference={handleUpdatePreference}
                                    newPreference={newPreference}
                                    dropdownOptions={dropdownOptions}
                                    isEditing={isEditing}
                                    preferenceLoaded={preferenceLoaded}
                                    isAdding={isAdding}
                                    userTier={subscriptionStatus.status}
                                    preferences={preferences}
                                    onAddPreference={handleShowModal}
                                />
                            )}
                        </main>
                    )}
                </div>
            )}
            <Footer />
        </div>
    );
};
export default Dashboard;
