import React from 'react';
import { AlertTriangle, Pause, Play, Trash2 } from 'lucide-react';

const ConfirmationDialog = ({
                                isOpen,
                                onClose,
                                onConfirm,
                                title,
                                message,
                                actionType
                            }) => {
    if (!isOpen) return null;

    const getIcon = () => {
        switch (actionType) {
            case 'delete':
                return <Trash2 className="h-6 w-6 text-red-500" />;
            case 'pause':
                return <Pause className="h-6 w-6 text-yellow-500" />;
            case 'resume':
                return <Play className="h-6 w-6 text-green-500" />;
            default:
                return <AlertTriangle className="h-6 w-6 text-yellow-500" />;
        }
    };

    const getActionColor = () => {
        switch (actionType) {
            case 'delete':
                return 'bg-red-600 hover:bg-red-700 focus:ring-red-500';
            case 'pause':
                return 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500';
            case 'resume':
                return 'bg-green-600 hover:bg-green-700 focus:ring-green-500';
            default:
                return 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500';
        }
    };

    return (
        <div className="fixed inset-0 z-[1000] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            {/* Overlay */}
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose} />

            {/* Modal Panel Container */}
            <div className="flex min-h-full items-center justify-center p-4">
                {/* Modal Panel */}
                <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-lg mx-4 sm:mx-auto">
                    <div className="bg-white p-6">
                        {/* Icon and Content */}
                        <div className="flex items-start space-x-4">
                            {/* Icon container - Fixed width for alignment */}
                            <div className="flex-shrink-0">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                                    {getIcon()}
                                </div>
                            </div>

                            {/* Text content */}
                            <div className="flex-1">
                                <h3 className="text-lg font-semibold text-gray-900" id="modal-title">
                                    {title}
                                </h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        {message}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Buttons - Fixed width and consistent spacing */}
                        <div className="mt-6 flex justify-end space-x-3">
                            <button
                                type="button"
                                onClick={onClose}
                                className="w-24 inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={onConfirm}
                                className={`w-24 inline-flex justify-center items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${getActionColor()}`}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;