import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, PlayCircle, StopCircle } from 'lucide-react';

const BackgroundTasksDashboard = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchTasks = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Admin/tasks`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch tasks');
            const data = await response.json();
            setTasks(data);
            setError(null);
        } catch (err) {
            setError(err.message);
            console.error('Error fetching tasks:', err);  // Added for debugging
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTasks();
        const interval = setInterval(fetchTasks, 5000); // Refresh every 5 seconds
        return () => clearInterval(interval);
    }, []);

    const toggleTask = async (taskName, enable) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Admin/tasks/${taskName}/toggle`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(enable)
            });

            if (!response.ok) throw new Error('Failed to update task status');
            fetchTasks();
        } catch (err) {
            setError(err.message);
            console.error('Error toggling task:', err);  // Added for debugging
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-4 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
                <AlertCircle className="h-5 w-5" />
                <span>{error}</span>
            </div>
        );
    }

    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold mb-6">Background Tasks Dashboard</h2>
            <div className="grid gap-4">
                {tasks.map((task) => (
                    <div key={task.name} className="bg-white p-4 rounded-lg shadow-sm border">
                        <div className="flex items-center justify-between">
                            <div>
                                <h3 className="font-semibold text-lg">{task.name}</h3>
                                <p className="text-gray-600 text-sm">{task.description}</p>
                                <div className="mt-2 flex items-center gap-2">
                                    {task.isEnabled ? (
                                        <span className="flex items-center text-green-600 text-sm">
                      <CheckCircle className="h-4 w-4 mr-1" />
                      Enabled
                    </span>
                                    ) : (
                                        <span className="flex items-center text-gray-600 text-sm">
                      <StopCircle className="h-4 w-4 mr-1" />
                      Disabled
                    </span>
                                    )}
                                    {task.lastRun && (
                                        <span className="text-sm text-gray-500">
                      Last run: {new Date(task.lastRun).toLocaleString()}
                    </span>
                                    )}
                                </div>
                            </div>
                            <button
                                onClick={() => toggleTask(task.name, !task.isEnabled)}
                                className={`p-2 rounded-full ${
                                    task.isEnabled
                                        ? 'bg-red-100 text-red-600 hover:bg-red-200'
                                        : 'bg-green-100 text-green-600 hover:bg-green-200'
                                }`}
                            >
                                {task.isEnabled ? (
                                    <StopCircle className="h-6 w-6" />
                                ) : (
                                    <PlayCircle className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BackgroundTasksDashboard;