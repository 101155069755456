import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Alert, Spinner, ListGroup } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";

const AddOrEditPreferenceModal = ({
                                      showModal,
                                      handleCloseModal,
                                      isEditing,
                                      errorMessage,
                                      newPreference,
                                      handleChange,
                                      handleAddPreference,
                                      handleUpdatePreference,
                                      dropdownOptions,
                                      isAdding,
                                  }) => {
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [isCityValid, setIsCityValid] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    // Debounced fetch city suggestions function
    const fetchCitySuggestions = useCallback(
        _.debounce(async (query) => {
            if (!query || query.length < 2) {
                setCitySuggestions([]);
                return;
            }
            setLoadingCities(true);
            try {
                const response = await axios.get('/api/Places/autocomplete', {
                    params: {
                        input: query
                    }
                });

                if (response.data.status === "OK" && response.data.predictions) {
                    setCitySuggestions(response.data.predictions);
                } else {
                    setCitySuggestions([]);
                }
            } catch (error) {
                console.error("Error fetching city suggestions:", error);
                setCitySuggestions([]);
            }
            setLoadingCities(false);
        }, 500),
        []
    );

    // Handle city input change
    const handleCityChange = (e) => {
        const { value } = e.target;
        handleChange(e);
        setIsCityValid(false);
        fetchCitySuggestions(value);
    };

    // Handle city suggestion selection
    const handleCitySelection = (prediction) => {
        // Extract only the main city name without country or additional info
        const cityName = prediction.structured_formatting.main_text;
        handleChange({
            target: { name: "city", value: cityName }
        });
        setCitySuggestions([]);
        setIsCityValid(true);
    };

    // Validate if user input matches any suggestion
    useEffect(() => {
        if (citySuggestions.some(pred => pred.description === newPreference.city)) {
            setIsCityValid(true);
        }
    }, [newPreference.city, citySuggestions]);

    // Handle the Update Preference action
    const handleUpdateClick = () => {
        setShowWarning(true);
    };

    // Handle the confirmation of the update action
    const confirmUpdate = () => {
        handleUpdatePreference();
        setShowWarning(false);
    };

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? "Edit Preference" : "Add New Preference"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form>
                        <Form.Group controlId="formCity" className="position-relative">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={newPreference.city || ""}
                                onChange={handleCityChange}
                                placeholder="Enter city name"
                                autoComplete="off"
                            />
                            {loadingCities && (
                                <div className="position-absolute end-0 top-50">
                                    <Spinner animation="border" size="sm" />
                                </div>
                            )}
                            {citySuggestions.length > 0 && (
                                <ListGroup className="position-absolute w-100 mt-1 z-1000">
                                    {citySuggestions.map((prediction) => (
                                        <ListGroup.Item
                                            key={prediction.place_id}
                                            action
                                            onClick={() => handleCitySelection(prediction)}
                                            className="cursor-pointer"
                                        >
                                            <div className="fw-bold">
                                                {prediction.structured_formatting.main_text}
                                            </div>
                                            <small className="text-muted">
                                                {prediction.structured_formatting.secondary_text}
                                            </small>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </Form.Group>

                        <Form.Group controlId="formPriceRangeMin">
                            <Form.Label>Min Price</Form.Label>
                            <Form.Control
                                as="select"
                                name="priceRangeMin"
                                value={newPreference.priceRangeMin || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Min Price</option>
                                {dropdownOptions.PriceRange.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formPriceRangeMax">
                            <Form.Label>Max Price</Form.Label>
                            <Form.Control
                                as="select"
                                name="priceRangeMax"
                                value={newPreference.priceRangeMax || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Max Price</option>
                                {dropdownOptions.PriceRange.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formPropertyType">
                            <Form.Label>Property Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="propertyType"
                                value={newPreference.propertyType || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Property Type</option>
                                {dropdownOptions.PropertyType.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formDistanceRadius">
                            <Form.Label>Distance Radius</Form.Label>
                            <Form.Control
                                as="select"
                                name="distanceRadius"
                                value={newPreference.distanceRadius || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Distance Radius</option>
                                {dropdownOptions.DistanceRadius.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formMinBedrooms">
                            <Form.Label>Min Bedrooms</Form.Label>
                            <Form.Control
                                as="select"
                                name="minBedrooms"
                                value={newPreference.minBedrooms === 0 ? "Studio" : newPreference.minBedrooms || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Min Bedrooms</option>
                                {dropdownOptions.Bedrooms.map((option, index) => (
                                    <option key={index} value={option === 0 ? "Studio" : option.toString()}>
                                        {option === 0 ? "Studio" : option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formMaxBedrooms">
                            <Form.Label>Max Bedrooms</Form.Label>
                            <Form.Control
                                as="select"
                                name="maxBedrooms"
                                value={newPreference.maxBedrooms || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Max Bedrooms</option>
                                {dropdownOptions.Bedrooms.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={isEditing ? handleUpdateClick : handleAddPreference}
                    >
                        {isEditing ? "Update Preference" : "Add Preference"}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Warning Modal */}
            <Modal show={showWarning} centered onHide={() => setShowWarning(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Preference Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Updating your preferences will remove all existing matching listings associated with this preference.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowWarning(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={confirmUpdate}>
                        Yes, Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddOrEditPreferenceModal;