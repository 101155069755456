// components/Context/AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../Services/AuthService';
import axios from 'axios';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

const Toast = ({ message, onClose }) => (
    <div className="fixed bottom-4 right-4 z-50 animate-fade-in">
        <div className="bg-green-500 text-white px-6 py-4 rounded-lg shadow-lg">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <svg className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <p>{message}</p>
                </div>
                {onClose && (
                    <button onClick={onClose} className="ml-4 hover:text-green-200">
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                )}
            </div>
        </div>
    </div>
);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [toast, setToast] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Set up axios interceptors
        const interceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    // Token is invalid or expired
                    handleLogout(true);
                }
                return Promise.reject(error);
            }
        );

        // Set default axios headers
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        // Clean up
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    useEffect(() => {
        checkAuthStatus();
    }, []);

    useEffect(() => {
        if (toast) {
            const timer = setTimeout(() => {
                setToast(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [toast]);

    const checkAuthStatus = () => {
        const token = localStorage.getItem('token');
        const adminToken = localStorage.getItem('adminToken');

        if (token) {
            try {
                const payload = JSON.parse(atob(token.split('.')[1]));
                const isExpired = Date.now() >= payload.exp * 1000;

                if (isExpired) {
                    handleLogout(true);
                } else {
                    setIsAuthenticated(true);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                }
            } catch (error) {
                handleLogout(true);
            }
        }

        if (adminToken) {
            try {
                const payload = JSON.parse(atob(adminToken.split('.')[1]));
                const isExpired = Date.now() >= payload.exp * 1000;

                if (isExpired) {
                    handleAdminLogout();
                } else {
                    setIsAdmin(true);
                }
            } catch (error) {
                handleAdminLogout();
            }
        }

        setIsLoading(false);
    };

    const handleLogout = async (isTokenExpired = false) => {
        try {
            if (!isTokenExpired) {
                await authService.logout();
            }
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            setIsAuthenticated(false);
            delete axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('token');
            localStorage.removeItem('userEmail');
            navigate('/');
            setToast(isTokenExpired ? 'Session expired. Please login again.' : 'Successfully logged out!');
        }
    };

    const handleAdminLogout = () => {
        localStorage.removeItem('adminToken');
        setIsAdmin(false);
        navigate('/admin');
        setToast('Successfully logged out of admin account!');
    };

    const refreshAuthState = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const payload = JSON.parse(atob(token.split('.')[1]));
                const isExpired = Date.now() >= payload.exp * 1000;

                if (!isExpired) {
                    setIsAuthenticated(true);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                }
            } catch (error) {
                handleLogout(true);
            }
        }
    };



    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            isAdmin,
            setIsAdmin,
            handleLogout,
            handleAdminLogout,
            refreshAuthState
        }}>
            {children}
            {toast && <Toast message={toast} onClose={() => setToast(null)} />}
        </AuthContext.Provider>
    );
};