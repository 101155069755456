import React from 'react';
import { ExternalLink } from 'lucide-react';

const ListingCard = ({ listing }) => (
    <div className="p-4 bg-white rounded-lg shadow-sm border border-gray-200 space-y-3">
        <div className="space-y-2">
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Location</span>
                <span className="text-sm text-gray-900">{listing.city}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Price</span>
                <span className="text-sm text-gray-900">£{listing.price.toLocaleString()}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Type</span>
                <span className="text-sm text-gray-900">{listing.propertyType}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Bedrooms</span>
                <span className="text-sm text-gray-900">{listing.actualBedrooms}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Added</span>
                <span className="text-sm text-gray-900">{new Date(listing.dateAdded).toLocaleDateString()}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Notification</span>
                <span className="text-sm text-gray-900">
          {listing.dateNotificationSent
              ? new Date(listing.dateNotificationSent).toLocaleDateString()
              : 'Not Sent'}
        </span>
            </div>
        </div>
        <div className="pt-2">
            <a
                href={listing.listingUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center gap-2 w-full px-4 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
            >
                <span>View Listing</span>
                <ExternalLink className="h-4 w-4" />
            </a>
        </div>
    </div>
);

export default ListingCard;