import React from 'react';
import Navbar from '../NavMenu';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
    const navigate = useNavigate();

    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    {/* Back Button */}
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center text-gray-600 hover:text-gray-900 mb-8"
                    >
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        Back
                    </button>

                    {/* Header Section */}
                    <div className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-gray-900">Privacy Policy</h1>
                        <p className="mt-4 text-gray-600">Last updated: November 02, 2024</p>
                    </div>

                    {/* Privacy Content */}
                    <div className="prose max-w-none">
                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Introduction</h2>
                            <p className="text-gray-600 mb-4">
                                At Dwellio, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our property alert service.
                            </p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Information We Collect</h2>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">2.1 Information You Provide:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>Email address and password for account creation</li>
                                <li>Property search preferences</li>
                                <li>Saved property listings</li>
                                <li>Custom alert criteria</li>
                                <li>Payment information (processed securely by Stripe)</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">2.2 Automatically Collected Information:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>IP address and location data</li>
                                <li>Browser type and version</li>
                                <li>Device information</li>
                                <li>Usage patterns and preferences</li>
                                <li>Cookie data</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. How We Use Your Information</h2>
                            <p className="text-gray-600 mb-4">We use your information to:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>Send property alerts based on your preferences</li>
                                <li>Process your subscription payments</li>
                                <li>Improve our property matching algorithms</li>
                                <li>Provide customer support</li>
                                <li>Send service updates and notifications</li>
                                <li>Analyze service usage and improve features</li>
                                <li>Prevent fraud and ensure security</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Third-Party Services</h2>
                            <p className="text-gray-600 mb-4">We use the following third-party services:</p>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">4.1 Payment Processing:</h3>
                            <p className="text-gray-600 mb-4">
                                We use Stripe for payment processing. When you make a payment, you provide your payment information directly to Stripe. We do not store your full payment details.
                            </p>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">4.2 Analytics:</h3>
                            <p className="text-gray-600 mb-4">
                                We use Google Analytics to understand how users interact with our service. This involves cookies and processing of IP addresses and other usage data.
                            </p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Cookies</h2>
                            <p className="text-gray-600 mb-4">We use cookies for:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>Essential functionality (login sessions, preferences)</li>
                                <li>Analytics and performance monitoring</li>
                                <li>Feature optimization</li>
                                <li>Personalized experience delivery</li>
                            </ul>
                            <p className="text-gray-600 mb-4">
                                You can control cookie preferences through your browser settings.
                            </p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Data Retention</h2>
                            <p className="text-gray-600 mb-4">
                                We retain your data for as long as your account is active or as needed to provide services. You can request data deletion by contacting us.
                            </p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Your Rights</h2>
                            <p className="text-gray-600 mb-4">You have the right to:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>Access your personal data</li>
                                <li>Correct inaccurate data</li>
                                <li>Request data deletion</li>
                                <li>Object to data processing</li>
                                <li>Export your data</li>
                                <li>Withdraw consent</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Data Security</h2>
                            <p className="text-gray-600 mb-4">
                                We implement appropriate security measures to protect your data, including encryption, secure servers, and regular security assessments.
                            </p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Children's Privacy</h2>
                            <p className="text-gray-600 mb-4">
                                Our service is not intended for users under 18. We do not knowingly collect information from children.
                            </p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Contact Us</h2>
                            <p className="text-gray-600 mb-4">For privacy-related inquiries:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>Email: eddie@dwellio.co.uk</li>
                            </ul>
                        </section>
                    </div>

                    {/* Footer Note */}
                    <div className="mt-12 p-6 bg-gray-50 rounded-lg">
                        <p className="text-gray-600 text-center">
                            By using Dwellio, you agree to the collection and use of information in accordance with this Privacy Policy.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Privacy;