import React from 'react';

const Footer = ({ onShowDemo }) => {
    return (
        <footer className="bg-gray-900 text-gray-300 py-12 dwellio-footer">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid md:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-white text-lg font-semibold mb-3">Dwellio</h3>
                        <p className="text-sm">
                            Making rental hunting easier and faster with real-time alerts and smart matching.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-white text-lg font-semibold mb-2">Product</h4>
                        <ul className="space-y-2">
                            <li>
                                <a href="/how-it-works" className="hover:text-white transition-colors">See Demo</a>
                            </li>
                            <li>
                                <a href="/subscriptions" className="hover:text-white transition-colors">Pricing</a>
                            </li>
                            <li>
                                <a href="/subscriptions#faq" className="hover:text-white transition-colors">FAQ</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="text-white text-lg font-semibold mb-2">Company</h4>
                        <ul className="space-y-2">
                            <li><a href="/contact" className="hover:text-white transition-colors">Contact</a></li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="text-white text-lg font-semibold mb-2">Legal</h4>
                        <ul className="space-y-2">
                            <li><a href="/privacy" className="hover:text-white transition-colors">Privacy Policy</a></li>
                            <li><a href="/terms" className="hover:text-white transition-colors">Terms of Service</a></li>
                            <li><a href="/cookies" className="hover:text-white transition-colors">Cookie Policy</a></li>
                        </ul>
                    </div>
                </div>
                <div className="mt-8 pt-8 border-t border-gray-800 text-center">
                    <p className="text-sm">
                        © 2025 Dwellio. All rights reserved.
                        <span className="ml-2 text-gray-500">v{process.env.REACT_APP_VERSION}</span>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;