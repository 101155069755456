import React, {useEffect, useState} from 'react';
import Navbar from './NavMenu';
import {
  ArrowRight,
  Search,
  Star,
  Shield,
  Check,
  X,
  ChevronRight,
  MapPin,
  PoundSterling,
  Home as HomeIcon,
  Calendar,
  CheckCircle2,
  AlertCircle
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Settings, Clock, Bell, Filter } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import Footer from './Footer';

const Home = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const [showDemo, setShowDemo] = useState(false);
  const canonicalUrl = 'https://dwellio.co.uk/';

  // Demo alert simulation data
  const demoAlerts = [
    {
      time: "2 minutes ago",
      property: "2 Bed Flat in Manchester",
      price: "£1,200/month",
      type: "New Listing"
    },
    {
      time: "15 minutes ago",
      property: "Studio in Liverpool",
      price: "£750/month",
      type: "Price Reduced"
    },
    {
      time: "1 hour ago",
      property: "3 Bed House in Leeds",
      price: "£1,500/month",
      type: "New Listing"
    }
  ];

  const steps = [
    {
      title: "Set Location",
      icon: MapPin,
      description: "Choose your preferred areas",
      fields: ["City", "Neighborhoods", "Commute Time"]
    },
    {
      title: "Property Details",
      icon: HomeIcon,
      description: "Specify your requirements",
      fields: ["Type", "Bedrooms", "Amenities"]
    },
    {
      title: "Budget & Terms",
      icon: PoundSterling,
      description: "Set your financial criteria",
      fields: ["Monthly Rent", "Term Length", "Move-in Date"]
    }
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavigateToSubscriptions = () => {
    navigate('/subscriptions');
    window.scrollTo(0, 0);
  };

  const handleHowItWorks = () => {
    navigate('/how-it-works');
    window.scrollTo(0, 0);
  };

  const HeroSection = () => (
      <section className="relative min-h-screen" aria-label="Hero section">
        <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url('${isMobile ? '/mobile-bg.jpg' : '/hero-bg.jpg'}')`,
            }}
            role="img"
            aria-label="Modern rental property interior background"
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/70 to-black/90 md:from-black/60 md:via-black/50 md:to-black/60"></div>
        </div>

        <div className="relative z-10 max-w-7xl mx-auto px-4 pt-20 md:pt-32 text-white text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Find your perfect rental
            <br />
            <span className="text-blue-400">before anyone else sees it</span>
          </h1>

          <h2 className="text-2xl md:text-3xl mb-8">
            UK's Leading Rental Property Alert Service
          </h2>

          <p className="text-lg mb-8">
            <strong className="font-bold">Save hours</strong> of manual searching with our automated alerts.
            <strong className="font-bold"> Be first</strong> to contact agents for viewings.
          </p>

          <div className="flex flex-col md:flex-row justify-center gap-4 mt-8">
            <a
                href="/subscriptions"
                onClick={handleNavigateToSubscriptions}
                className="w-full md:w-auto px-8 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors flex items-center justify-center"
                aria-label="Start your free trial with Dwellio"
            >
              Start Free Trial
              <ArrowRight className="ml-2 h-5 w-5" aria-hidden="true"/>
            </a>
            <a
                href="/how-it-works"
                onClick={handleHowItWorks}
                className="w-full md:w-auto px-8 py-3 rounded-lg bg-transparent border-2 border-white text-white font-medium hover:bg-white/10 transition-colors"
                aria-label="Learn how Dwellio works"
            >
              See How It Works
            </a>
          </div>

          <nav className="flex flex-col md:flex-row gap-8 mt-16 md:mt-32 px-4 md:px-0 overflow-x-auto pb-8">
            {[
              {
                title: "Set Your Preferences",
                description: "Tell us what you're looking for - location, budget, property type, and features. We'll create customized search criteria to match your perfect rental.",
                icon: Settings,
                link: "/how-it-works"
              },
              {
                title: "We Monitor 24/7",
                description: "Our system continuously checks RightMove, Zoopla, and other major property sites multiple times per hour to catch new listings instantly.",
                icon: Clock,
                link: "/how-it-works"
              },
              {
                title: "Instant Alerts",
                description: "Get notified within minutes when matching properties are listed. Beat other renters to viewings and increase your chances of securing your ideal home.",
                icon: Bell,
                link: "/how-it-works"
              },
              {
                title: "Smart Filtering",
                description: "Our intelligent system analyzes listings to ensure you only see properties that truly match your criteria, saving you time and effort.",
                icon: Filter,
                link: "/how-it-works"
              }
            ].map((step, i) => (
                <article key={i} className="text-center min-w-[250px] bg-black/30 p-6 rounded-lg backdrop-blur-sm">
                  <div className="w-16 h-16 rounded-full bg-white/20 backdrop-blur-sm mb-4 mx-auto flex items-center justify-center">
                    <step.icon className="h-8 w-8 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-sm text-gray-200">
                    <strong>{step.description}</strong>
                  </p>
                  <a
                      href={step.link}
                      className="text-blue-400 hover:text-blue-300 mt-4 inline-block font-medium"
                      aria-label={`Learn more about ${step.title.toLowerCase()}`}
                  >
                    Learn more →
                  </a>
                </article>
            ))}
          </nav>
        </div>
      </section>
  );

  return (
      <>
        <Helmet>
          <title>Dwellio - Find Your Perfect Rental Property Before Anyone Else | UK's Real-Time Property Alerts</title>
          <meta name="description" content="Get instant alerts for new rental properties in your desired location. Be the first to view and secure your ideal home with Dwellio's real-time property notifications across the UK." />
          <link rel="canonical" href={canonicalUrl} />
          <meta property="og:title" content="Dwellio - Find Your Perfect Rental Property Before Anyone Else" />
          <meta property="og:description" content="Get instant alerts for new rental properties in your desired location. Be the first to view and secure your ideal home with Dwellio's real-time property notifications." />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Dwellio - Find Your Perfect Rental Property Before Anyone Else" />
          <meta name="twitter:description" content="Get instant alerts for new rental properties. Be the first to view and secure your ideal home with Dwellio's real-time notifications." />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebApplication",
              "name": "Dwellio",
              "description": "Real-time rental property alerts service in the UK",
              "url": canonicalUrl,
              "applicationCategory": "Property Search Tool",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "GBP"
              }
            })}
          </script>
        </Helmet>
        <Navbar/>

        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <HeroSection/>

          {/* Why Now Section */}
          <div className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900">
                  Why Speed Matters in Today's Rental Market
                </h2>
              </div>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="bg-white p-8 rounded-lg shadow-sm transform hover:scale-105 transition-transform">
                  <div className="text-4xl font-bold text-blue-600 mb-2">7+ Hours</div>
                  <p className="text-gray-600">saved per week by not manually checking multiple websites</p>
                </div>
                <div className="bg-white p-8 rounded-lg shadow-sm transform hover:scale-105 transition-transform">
                  <div className="text-4xl font-bold text-blue-600 mb-2">15 Minutes</div>
                  <p className="text-gray-600">average alert time after a property is listed</p>
                </div>
                <div className="bg-white p-8 rounded-lg shadow-sm transform hover:scale-105 transition-transform">
                  <div className="text-4xl font-bold text-blue-600 mb-2">3x More</div>
                  <p className="text-gray-600">likely to secure viewings when you're among first to contact</p>
                </div>
              </div>
            </div>
          </div>

          {/* Interactive How It Works Section */}
          <div className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900">
                  Start Finding Your Perfect Rental in 3 Easy Steps
                </h2>
              </div>
              <div className="relative">
                <div className="hidden md:block absolute top-1/2 left-0 right-0 h-0.5 bg-blue-100 -translate-y-1/2"/>

                <div className="grid md:grid-cols-3 gap-8 relative">
                  {steps.map((step, index) => (
                      <div
                          key={index}
                          className={`text-center cursor-pointer transition-all duration-300 ${
                              activeStep === index + 1 ? 'transform scale-105' : 'opacity-70'
                          }`}
                          onClick={() => setActiveStep(index + 1)}
                      >
                        <div className="relative">
                          <div
                              className={`mx-auto w-16 h-16 rounded-full flex items-center justify-center mb-4 transition-colors ${
                                  activeStep === index + 1 ? 'bg-blue-600' : 'bg-blue-100'
                              }`}>
                            <step.icon className={`h-8 w-8 ${
                                activeStep === index + 1 ? 'text-white' : 'text-blue-600'
                            }`}/>
                          </div>
                        </div>
                        <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                        <p className="text-gray-600 mb-4">{step.description}</p>
                        {activeStep === index + 1 && (
                            <div className="space-y-2">
                              {step.fields.map((field, fieldIndex) => (
                                  <div key={fieldIndex}
                                       className="flex items-center justify-center text-sm text-gray-500">
                                    <CheckCircle2 className="h-4 w-4 text-blue-600 mr-2"/>
                                    {field}
                                  </div>
                              ))}
                            </div>
                        )}
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Alert Demo Modal */}
          {showDemo && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-2xl max-w-2xl w-full p-8 relative">
                  <button
                      onClick={() => setShowDemo(false)}
                      className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                  >
                    <X className="h-6 w-6"/>
                  </button>

                  <h3 className="text-2xl font-bold text-gray-900 mb-6">
                    See How Fast You'll Get Alerts
                  </h3>

                  <div className="space-y-4">
                    {demoAlerts.map((alert, index) => (
                        <div key={index}
                             className="border border-gray-200 rounded-lg p-4 hover:border-blue-500 transition-colors">
                          <div className="flex items-center justify-between mb-2">
                            <span className="text-sm text-gray-500">{alert.time}</span>
                            <span className="text-xs font-medium px-2 py-1 bg-blue-100 text-blue-600 rounded-full">
                        {alert.type}
                      </span>
                          </div>
                          <h4 className="font-semibold">{alert.property}</h4>
                          <p className="text-gray-600">{alert.price}</p>
                        </div>
                    ))}
                  </div>

                  <div className="mt-8 text-center">
                    <button
                        onClick={() => {
                          setShowDemo(false);
                          navigate('/subscriptions');
                        }}
                        className="px-8 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                    >
                      Start Getting Alerts
                    </button>
                  </div>
                </div>
              </div>
          )}
          {/* Comparison Section */}
          <div className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900">
                  The Dwellio Advantage
                </h2>
                <p className="mt-4 text-xl text-gray-600">
                  See how Dwellio transforms your rental search
                </p>
              </div>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-white p-8 rounded-lg shadow-sm">
                  <h3 className="text-xl font-semibold mb-6 text-red-600 flex items-center" style={{marginLeft: '32px'}}>
                    <AlertCircle className="h-6 w-6 mr-2"/>
                    Without Dwellio
                  </h3>
                  <ul className="space-y-6">
                    <li className="flex items-start">
                      <X className="h-5 w-5 text-red-500 mr-3 mt-1 flex-shrink-0"/>
                      <div>
                        <span className="font-medium">Manual Property Search</span>
                        <p className="text-sm text-gray-600 mt-1">Spending hours checking multiple websites daily</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <X className="h-5 w-5 text-red-500 mr-3 mt-1 flex-shrink-0"/>
                      <div>
                        <span className="font-medium">Late to Know</span>
                        <p className="text-sm text-gray-600 mt-1">Properties already let when you enquire</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <X className="h-5 w-5 text-red-500 mr-3 mt-1 flex-shrink-0"/>
                      <div>
                        <span className="font-medium">Missing Opportunities</span>
                        <p className="text-sm text-gray-600 mt-1">Perfect properties gone before you even see them</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="bg-white p-8 rounded-lg shadow-sm">
                  <h3 className="text-xl font-semibold mb-6 text-green-600 flex items-center" style={{marginLeft: '32px'}}>
                    <Check className="h-6 w-6 mr-2"/>
                    With Dwellio
                  </h3>
                  <ul className="space-y-6">
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0"/>
                      <div>
                        <span className="font-medium">Automated Alerts</span>
                        <p className="text-sm text-gray-600 mt-1">Instant notifications for new properties matching your
                          criteria</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0"/>
                      <div>
                        <span className="font-medium">First to Know</span>
                        <p className="text-sm text-gray-600 mt-1">Be among the first to contact agents and arrange
                          viewings</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0"/>
                      <div>
                        <span className="font-medium">Never Miss Out</span>
                        <p className="text-sm text-gray-600 mt-1">Catch every relevant listing in your desired areas</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Enhanced Testimonials Section */}
          <div className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900">
                  What Our Users Say
                </h2>
                <p className="mt-4 text-xl text-gray-600">
                  Join thousands who found their perfect rental with Dwellio
                </p>
              </div>
              <div className="grid md:grid-cols-3 gap-8">
                {[
                  {
                    name: "Sarah J.",
                    location: "Manchester",
                    role: "Young Professional",
                    testimonial: "Found my dream flat in 2 days! The instant alerts made all the difference in this competitive market.",
                    achievement: "Secured property within 48 hours",
                    plan: "Pro Plan"
                  },
                  {
                    name: "James K.",
                    location: "London",
                    role: "Student",
                    testimonial: "As a student, I needed to find a property quickly before term started. Dwellio helped me secure a great house share.",
                    achievement: "Found perfect student housing",
                    plan: "Basic Plan"
                  },
                  {
                    name: "Emma T.",
                    location: "Birmingham",
                    role: "Remote Worker",
                    testimonial: "Being able to search in multiple areas helped me find the perfect work-from-home space within my budget.",
                    achievement: "Searched 3 different areas",
                    plan: "Pro Plan"
                  }
                ].map((testimonial, index) => (
                    <div key={index} className="bg-gray-50 p-6 rounded-lg hover:shadow-md transition-shadow">
                      <div className="flex items-center mb-4">
                        <img src="/user.png" width={40} height={40} alt="User" className="rounded-full"/>
                        <div className="ml-3">
                          <h4 className="font-semibold">{testimonial.name}</h4>
                          <p className="text-sm text-gray-600">{testimonial.location}</p>
                        </div>
                      </div>
                      <p className="text-gray-600 mb-4">"{testimonial.testimonial}"</p>
                      <div className="flex items-center justify-between text-sm">
                        <span className="text-blue-600">{testimonial.plan}</span>
                        <span className="bg-green-100 text-green-600 px-2 py-1 rounded-full">
                      {testimonial.achievement}
                    </span>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>

          {/* Updated CTA Section */}
          <div className="bg-blue-600 py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="bg-white/10 rounded-2xl p-8 backdrop-blur-sm">
                <div className="text-center">
                  <h2 className="text-3xl font-bold text-white mb-4">
                    Start Your Rental Search Today
                  </h2>
                  <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
                    Don't miss out on your perfect rental property. Join thousands of satisfied users who found their
                    ideal home using Dwellio.
                  </p>
                  <div className="flex flex-col sm:flex-row gap-4 justify-center">
                    <button
                        onClick={handleNavigateToSubscriptions}
                        className="px-8 py-3 rounded-lg bg-white text-blue-600 font-medium hover:bg-gray-100 transition-colors flex items-center justify-center"
                    >
                      Start Free Trial
                      <ArrowRight className="ml-2 h-5 w-5"/>
                    </button>
                    <button
                        onClick={handleNavigateToSubscriptions}
                        className="px-8 py-3 rounded-lg bg-transparent border-2 border-white text-white font-medium hover:bg-blue-700 transition-colors"
                    >
                      View Pricing
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer onShowDemo={() => setShowDemo(true)} />
      </>
  );
};

export default Home;