import React, { useState } from 'react';
import { Edit3, Pause, Play, Trash2 } from 'lucide-react';
import EmptyState from "./EmptyState";
import PreferenceCard from "./MobileView/PreferenceCard";
import ConfirmationDialog from "./Helpers/ConfirmationDialog";

const PreferencesTable = ({ preferences, onEdit, onDelete, onPause, onAddPreference }) => {
    // State for managing confirmation dialog
    const [confirmationDialog, setConfirmationDialog] = useState({
        isOpen: false,
        type: null,
        preferenceId: null,
        currentStatus: null
    });

    // Helper function to get confirmation dialog messages
    const getConfirmationMessage = (type, preference) => {
        switch (type) {
            case 'delete':
                return {
                    title: "Delete Preference",
                    message: `Are you sure you want to delete your preference for ${preference.city}? This action cannot be undone.`
                };
            case 'pause':
                return {
                    title: "Pause Preference",
                    message: `Are you sure you want to pause your preference for ${preference.city}? You won't receive notifications for this preference while it's paused.`
                };
            case 'resume':
                return {
                    title: "Resume Preference",
                    message: `Are you sure you want to resume your preference for ${preference.city}? You'll start receiving notifications for this preference again.`
                };
            default:
                return { title: "", message: "" };
        }
    };

    // Handle confirmation of actions
    const handleConfirmAction = () => {
        const { type, preferenceId, currentStatus } = confirmationDialog;

        if (type === 'delete') {
            onDelete(preferenceId);
        } else if (type === 'pause' || type === 'resume') {
            onPause(preferenceId, !currentStatus);
        }

        // Reset dialog state after action
        setConfirmationDialog({
            isOpen: false,
            type: null,
            preferenceId: null,
            currentStatus: null
        });
    };

    // Handle delete button click
    const handleDeleteClick = (preference) => {
        setConfirmationDialog({
            isOpen: true,
            type: 'delete',
            preferenceId: preference.id,
            currentStatus: null
        });
    };

    // Handle pause/resume button click
    const handlePauseResumeClick = (preference) => {
        setConfirmationDialog({
            isOpen: true,
            type: preference.isActive ? 'pause' : 'resume',
            preferenceId: preference.id,
            currentStatus: preference.isActive
        });
    };

    return (
        <div className="bg-white rounded-lg shadow-sm p-6 relative space-y-4 mb-8">
            {/* Header */}
            <div className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-900">Your Preferences</h2>
                <p className="text-gray-600 mt-1">Manage your property search criteria</p>
            </div>

            {/* Desktop View */}
            <div className="hidden md:block">
                <table className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price Range</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bedrooms</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Listings Matched</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!preferences?.length ? (
                        <tr>
                            <td colSpan="7" className="px-6 py-4">
                                <EmptyState type="preferences" onAction={onAddPreference} />
                            </td>
                        </tr>
                    ) : (
                        preferences.map((pref) => (
                            <tr key={pref.id} className="hover:bg-gray-50">
                                {/* Status Column */}
                                <td className="px-6 py-4">
                                    {pref.isActive ? (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                Active
                                            </span>
                                    ) : (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                Paused
                                            </span>
                                    )}
                                </td>
                                {/* Data Columns */}
                                <td className="px-6 py-4 text-sm text-gray-900">{pref.city}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">£{pref.priceRangeMin} - £{pref.priceRangeMax}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">{pref.propertyType}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">{pref.minBedrooms} - {pref.maxBedrooms}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">{pref.listingsMatched}</td>
                                {/* Action Buttons */}
                                <td className="px-6 py-4 text-sm text-gray-500">
                                    <div className="flex space-x-3">
                                        <button
                                            onClick={() => onEdit(pref.id)}
                                            className="text-blue-600 hover:text-blue-800">
                                            <Edit3 className="h-4 w-4" />
                                        </button>
                                        <button
                                            onClick={() => handlePauseResumeClick(pref)}
                                            className="text-gray-600 hover:text-gray-800">
                                            {pref.isActive ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
                                        </button>
                                        <button
                                            onClick={() => handleDeleteClick(pref)}
                                            className="text-red-600 hover:text-red-800">
                                            <Trash2 className="h-4 w-4" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            {/* Mobile View */}
            <div className="md:hidden space-y-4">
                {!preferences?.length ? (
                    <EmptyState type="preferences" onAction={onAddPreference} />
                ) : (
                    preferences.map(pref => (
                        <PreferenceCard
                            key={pref.id}
                            pref={pref}
                            onEdit={onEdit}
                            onDelete={() => handleDeleteClick(pref)}
                            onPause={() => handlePauseResumeClick(pref)}
                        />
                    ))
                )}
            </div>

            {/* Confirmation Dialog */}
            {confirmationDialog.isOpen && (
                <ConfirmationDialog
                    isOpen={confirmationDialog.isOpen}
                    onClose={() => setConfirmationDialog({
                        isOpen: false,
                        type: null,
                        preferenceId: null,
                        currentStatus: null
                    })}
                    onConfirm={handleConfirmAction}
                    {...getConfirmationMessage(
                        confirmationDialog.type,
                        preferences.find(p => p.id === confirmationDialog.preferenceId)
                    )}
                    actionType={confirmationDialog.type}
                />
            )}
        </div>
    );
};

export default PreferencesTable;