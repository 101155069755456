// services/authService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const authService = {
    async login(email, password) {
        const response = await axios.post(`${API_URL}/api/auth/login`, {
            email,
            password,
        });
        return response.data;
    },

    async register(email, password) {
        const response = await axios.post(`${API_URL}/api/auth/register`, {
            email,
            password,
        });
        return response.data;
    },

    async resendVerification(email) {
        const response = await axios.post(`${API_URL}/api/auth/resend-verification`, {
            email,
        });
        return response.data;
    },

    async logout() {
        const response = await axios.post(`${API_URL}/api/auth/logout`);
        return response.data;
    },

    async getProfile() {
        const response = await axios.get(`${API_URL}/api/auth/profile`);
        return response.data;
    },

    async updateProfile(email) {
        const response = await axios.post(`${API_URL}/api/auth/profile/update`, {
            email,
        });
        return response.data;
    },

    async changePassword(currentPassword, newPassword) {
        const response = await axios.post(`${API_URL}/api/auth/profile/change-password`, {
            currentPassword,
            newPassword,
        });
        return response.data;
    },

    async forgotPassword(email) {
        const response = await axios.post(`${API_URL}/api/auth/forgot-password`, {
            email,
        });
        return response.data;
    },

    async resetPassword(email, token, newPassword) {
        const response = await axios.post(`${API_URL}/api/auth/reset-password`, {
            email,
            token,
            newPassword,
        });
        return response.data;
    },

    async adminLogin(email, password) {
        const response = await axios.post(`${API_URL}/api/auth/admin/login`, {
            email,
            password,
        });
        return response.data;
    },

    googleSignIn: async (credential) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/Auth/google-signin`,
                { idToken: credential },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Google Sign In Error:', error);
            throw error.response?.data || error;
        }
    }
};