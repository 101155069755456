// components/PlanSummary.js
import React from 'react';
import { Check } from 'lucide-react';

const PlanSummary = ({ tier, onChangePlan }) => {
    const planDetails = {
        free: {
            price: '£0',
            period: 'month',
            features: [
                "1 rental search preference",
                "3 latest listings per day",
                "1 daily email digest",
                "24-hour notification delay",
                "Basic rental listing details"
            ]
        },
        basic: {
            price: '£6.99',
            period: 'month',
            features: [
                "3 search preferences for different areas",
                "20 latest rental listings",
                "3 email alerts per day",
                "2-hour notification delay",
                "Quick-view property details",
                "Priority listing access"
            ]
        },
        pro: {
            price: '£12.99',
            period: 'month',
            features: [
                "✨ Unlimited search preferences",
                "⚡ Instant notifications (15-min max)",
                "📨 Unlimited daily alerts",
                "🔍 Detailed property information",
                "⭐ Priority email support",
                "🏃‍♂️ Be first to view properties"
            ]
        }
    };

    // Convert tier to lowercase for case-insensitive matching
    const normalizedTier = tier.toLowerCase();
    const details = planDetails[normalizedTier];

    if (!details) {
        console.error(`No plan details found for tier: ${tier}`);
        return null;
    }

    return (
        <div className="rounded-lg border border-gray-200 bg-white p-6">
            <div className="flex items-center justify-between mb-4">
                <div>
                    <h3 className="text-lg font-semibold text-gray-900">{tier} Plan</h3>
                    <p className="text-gray-600">
                        <span className="text-2xl font-bold text-gray-900">{details.price}</span>
                        /{details.period}
                    </p>
                </div>
                <button
                    onClick={onChangePlan}
                    className="text-blue-600 hover:text-blue-700 text-sm font-medium"
                >
                    Change Plan
                </button>
            </div>
            <ul className="space-y-2">
                {details.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                        <Check className="h-4 w-4 text-green-500 mr-2" />
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PlanSummary;