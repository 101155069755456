import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { useAuth } from './Context/AuthProvider';
import { authService } from './Services/AuthService';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, handleLogout } = useAuth();
  const isHomePage = window.location.pathname === '/';

  const navLinks = [
    { path: '/', name: 'Home' },
    { path: '/subscriptions', name: 'Subscription' },
    { path: '/contact', name: 'Contact Us' },
    { path: '/how-it-works', name: 'How It Works' },
  ];

  if (isAuthenticated) {
    navLinks.push(
        { path: '/dashboard', name: 'Dashboard' },
        { path: '/profile', name: 'Profile' }
    );
  } else {
    navLinks.push({ path: '/login', name: 'Login' });
  }

  const onLogout = async () => {
    try {
      await authService.logout();
      handleLogout();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const MobileMenu = ({ isOpen, setIsOpen, navLinks, isAuthenticated }) => (
      <div className="md:hidden">
        <button
            onClick={() => setIsOpen(!isOpen)}
            className={`p-2 rounded-md ${isHomePage ? 'text-white' : 'text-gray-600'}`}
        >
          {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </button>

        {isOpen && (
            <div className="fixed top-16 left-0 w-full bg-black/90 backdrop-blur-sm">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navLinks.map(link => (
                    <Link
                        key={link.path}
                        to={link.path}
                        className="block px-3 py-2 text-base font-medium text-white hover:text-blue-200"
                        onClick={() => setIsOpen(false)}
                    >
                      {link.name}
                    </Link>
                ))}
                {isAuthenticated ? (
                    <button
                        onClick={() => {
                          onLogout();
                          setIsOpen(false);
                        }}
                        className="block w-full text-left px-3 py-2 text-base font-medium text-white hover:text-blue-200"
                    >
                      Logout
                    </button>
                ) : (
                    <Link
                        to="/register"
                        className="block px-3 py-2 text-base font-medium text-white hover:text-blue-200"
                        onClick={() => setIsOpen(false)}
                    >
                      Register
                    </Link>
                )}
              </div>
            </div>
        )}
      </div>
  );

  return (
      <nav className={`w-full z-20 ${isHomePage ? 'absolute bg-transparent' : 'bg-white border-b border-gray-100'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center dwellio-nav">
            <Link to="/" className="flex items-center">
            <span className={`text-xl font-bold ${isHomePage ? 'text-white' : 'text-gray-900'}`}>
              Dwellio
            </span>
            </Link>

            <div className="hidden md:flex items-center space-x-6">
              {navLinks.map(link => (
                  <Link
                      key={link.path}
                      to={link.path}
                      className={`px-3 py-2 text-sm font-medium no-underline
                  ${isHomePage
                          ? 'text-white hover:text-blue-200'
                          : 'text-gray-600 hover:text-gray-900'}`}
                  >
                    {link.name}
                  </Link>
              ))}

              {isAuthenticated ? (
                  <button
                      onClick={onLogout}
                      className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium"
                  >
                    Logout
                  </button>
              ) : (
                  <Link
                      to="/register"
                      className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium no-underline"
                  >
                    Register
                  </Link>
              )}
            </div>

            <MobileMenu
                isOpen={isMenuOpen}
                setIsOpen={setIsMenuOpen}
                navLinks={navLinks}
                isAuthenticated={isAuthenticated}
                isHomePage={isHomePage}
            />
          </div>
        </div>
      </nav>
  );
};

export default Navbar;