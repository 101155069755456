// EmailVerificationModal.js
import React, {useState} from 'react';
import axios from 'axios';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { AlertCircle, Mail } from 'lucide-react';

const EmailVerificationModal = ({ show, onClose }) => {
    const [isResending, setIsResending] = useState(false);
    const [resendStatus, setResendStatus] = useState(null); // 'success', 'error', or null

    const handleResendVerification = async () => {
        setIsResending(true);
        setResendStatus(null);

        try {
            const email = localStorage.getItem('userEmail'); // You'll need to store this during login
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/resend-verification`, {
                email: email
            });
            setResendStatus('success');
        } catch (error) {
            setResendStatus('error');
            console.error('Error resending verification:', error);
        } finally {
            setIsResending(false);
        }
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <div className="p-6">
                <div className="flex items-center gap-4 mb-4">
                    <div className="flex-shrink-0">
                        <AlertCircle className="h-8 w-8 text-blue-500" />
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900">
                            Email Verification Required
                        </h3>
                        <p className="text-sm text-gray-600">
                            Please verify your email address to access the dashboard.
                            Check your inbox for the verification link.
                        </p>
                    </div>
                </div>

                <div className="mt-4">
                    {resendStatus === 'success' && (
                        <div className="p-3 mb-4 bg-green-50 text-green-700 rounded-md">
                            Verification email sent successfully! Please check your inbox.
                        </div>
                    )}

                    {resendStatus === 'error' && (
                        <div className="p-3 mb-4 bg-red-50 text-red-700 rounded-md">
                            Failed to send verification email. Please try again.
                        </div>
                    )}

                    <button
                        onClick={handleResendVerification}
                        disabled={isResending}
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                    >
                        {isResending ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            <>
                                <Mail className="h-4 w-4 mr-2" />
                                Resend Verification Email
                            </>
                        )}
                    </button>

                    <button
                        onClick={onClose}
                        className="mt-3 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EmailVerificationModal;