import React from 'react';
import { Edit3, Pause, Play, Trash2 } from 'lucide-react';

const PreferenceCard = ({ pref, onEdit, onDelete, onPause }) => (
    <div className="p-4 bg-white rounded-lg shadow-sm border border-gray-200 space-y-3">
        <div className="flex justify-between items-start">
            <div>
                {pref.isActive ? (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Active
          </span>
                ) : (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Paused
          </span>
                )}
            </div>
            <div className="flex space-x-3">
                <button onClick={() => onEdit(pref.id)} className="text-blue-600 hover:text-blue-800">
                    <Edit3 className="h-4 w-4" />
                </button>
                <button onClick={() => onPause(pref.id, !pref.isActive)} className="text-gray-600 hover:text-gray-800">
                    {pref.isActive ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
                </button>
                <button onClick={() => onDelete(pref.id)} className="text-red-600 hover:text-red-800">
                    <Trash2 className="h-4 w-4" />
                </button>
            </div>
        </div>

        <div className="space-y-2">
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Location</span>
                <span className="text-sm text-gray-900">{pref.city}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Price Range</span>
                <span className="text-sm text-gray-900">£{pref.priceRangeMin} - £{pref.priceRangeMax}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Type</span>
                <span className="text-sm text-gray-900">{pref.propertyType}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Bedrooms</span>
                <span className="text-sm text-gray-900">{pref.minBedrooms} - {pref.maxBedrooms}</span>
            </div>
            <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Listings Matched</span>
                <span className="text-sm text-gray-900">{pref.listingsMatched}</span>
            </div>
        </div>
    </div>
);

export default PreferenceCard;