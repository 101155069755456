import React from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';

const CustomPagination = ({ currentPage, totalPageCount, onPageChange, userTier }) => {
    if (userTier !== 'Pro' || totalPageCount <= 1) {
        return null;
    }

    const generatePageNumbers = () => {
        const delta = 2;
        const range = [];
        const rangeWithDots = [];

        range.push(1);

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i > 1 && i < totalPageCount) {
                range.push(i);
            }
        }

        if (totalPageCount !== 1) {
            range.push(totalPageCount);
        }

        let prev = 0;
        for (const i of range) {
            if (prev + 1 !== i) {
                rangeWithDots.push('...');
            }
            rangeWithDots.push(i);
            prev = i;
        }

        return rangeWithDots;
    };

    const pageNumbers = generatePageNumbers();

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPageCount) {
            onPageChange(page);
        }
    };

    return (
        <div className="w-full flex justify-center">
            <div className="w-96 flex justify-center items-center">
                <nav className="flex items-center justify-center space-x-2">
                    <button
                        onClick={() => handlePageClick(1)}
                        disabled={currentPage === 1}
                        className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                        aria-label="First page"
                    >
                        <ChevronsLeft className="w-5 h-5" />
                    </button>

                    <button
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                        aria-label="Previous page"
                    >
                        <ChevronLeft className="w-5 h-5" />
                    </button>

                    <div className="flex items-center space-x-1 min-w-48 justify-center">
                        {pageNumbers.map((pageNumber, index) => (
                            pageNumber === '...' ? (
                                <span key={`dots-${index}`} className="px-3 py-2">...</span>
                            ) : (
                                <button
                                    key={`page-${pageNumber}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                    className={`px-3 py-2 rounded-lg min-w-8 ${
                                        currentPage === pageNumber
                                            ? 'bg-blue-600 text-white'
                                            : 'hover:bg-gray-100'
                                    }`}
                                >
                                    {pageNumber}
                                </button>
                            )
                        ))}
                    </div>

                    <button
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPageCount}
                        className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                        aria-label="Next page"
                    >
                        <ChevronRight className="w-5 h-5" />
                    </button>

                    <button
                        onClick={() => handlePageClick(totalPageCount)}
                        disabled={currentPage === totalPageCount}
                        className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                        aria-label="Last page"
                    >
                        <ChevronsRight className="w-5 h-5" />
                    </button>
                </nav>
            </div>
        </div>
    );
};

export default CustomPagination;